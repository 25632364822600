import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layouts/layout'

const ServicePageTemplate = ({data, pageContext}) =>{

  const { contentfulServices } = data

  return(
    <Layout>
      <Container className="py-5 full-height-container">
        <Row className="back-button-wrapper">
          <Col>
            <Link className="back-button" to="services">mes services</Link>
          </Col>
        </Row>
        <Row className="work-container">
          <Col md="12" lg="3" className="text-center image-wrapper">
            <Img className="image" sizes={contentfulServices.picto.sizes}/>
          </Col>
          <Col md="12" lg="9" className="px-5">
            <h1>{ contentfulServices.titre }</h1>
            <div className="big" dangerouslySetInnerHTML={{__html: contentfulServices.description.childMarkdownRemark.html}}/>
          </Col>
          {
            contentfulServices.contenu && contentfulServices.contenu.childMarkdownRemark.html &&
            <Col md="12" className="mt-5">
              <h2 className="mb-4">Quelques exemples</h2>
              <div className="innerhtml-content" dangerouslySetInnerHTML={{__html: contentfulServices.contenu.childMarkdownRemark.html }}/>
            </Col>
          }
        </Row>
      </Container>
    </Layout>
  )
}

export default ServicePageTemplate

export const servicePageQuery = graphql`
  query($id: String!) {
    contentfulServices(id: { eq: $id }) {
      id
      slug
      titre
      picto {
        id
        sizes(maxWidth: 200){
          ...GatsbyContentfulSizes
        } 
      }
      description {
        id
        childMarkdownRemark{
          html
        }
      }
      contenu {
        id
        childMarkdownRemark{
          html
        }
      }
    }
  }
`
